<template>
  <v-dialog v-model="fullScreenDialog" class="hidden-print-only" fullscreen hide-overlay
            transition="dialog-bottom-transition">
    <v-card>
      <!--            <div id="watermark">-->
      <v-toolbar color="indigo" dark>
        <v-btn dark icon @click="hideFullScreenDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ titleFullScreenDialog }}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn dark text @click="hideFullScreenDialog()">Close</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div v-if="sortedResult.length > 0">
        <div v-for="(data, index) in sortedResult" id="watermark" :key="index" class="ma-2 pa-2"
             style="background-repeat: repeat;" v-bind:style="{ backgroundImage: `url('${getWatermark}')` }"
        >


          <div class="content pa-2">
            <p class="title text-break">Q{{ index + 1 }}: {{ data.q }} </p>
            <!--suppress HtmlUnknownTarget -->
            <v-img v-if="data.i" :src="data.i" alt="loading image"
                   aspect-ratio="1"
                   contain height="300px"
                   lazy-src="https://picsum.photos/id/11/100/60"
                   width="250px">
              <template v-slot:placeholder>
                <v-row
                    align="center"
                    class="fill-height ma-0"
                    justify="center"
                >
                  <v-progress-circular color="indigo lighten-5" indeterminate/>
                </v-row>
              </template>
            </v-img>
            <div v-for="(option, index ) in data.o" :key="option">

              <p>{{ getCharacter(index) }}{{ option }}</p>
            </div>
            <p v-if="data.o[data.useranswered]"><strong>You Answered:</strong>
              {{ data.o[data.useranswered] }}
            </p>
            <p v-else><strong>You Answered:</strong> NOT ATTEMPTED </p>
            <p><strong>Correct Answer:</strong> {{ data.o[data.c] }} </p>
            <p v-html="data.d"/>
            <hr>
          </div>


        </div>
      </div>
      <div v-else class="text-center pa-4 ma-4">
        No Questions in this category.
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'FullScreenDialog',
  props: {
    // fullScreenDialog: {},
    sortedResult: {},
    titleFullScreenDialog: {}
  },
  computed: {
    getWatermark() {
      let waterMark = "https://duparedentalclinic.com/be-webapp/watermark/" + this.getEmail;
      return this.$vuetify.theme.dark ? waterMark + '/dark' : waterMark + '/light';
    },
    fullScreenDialog() {
      return this.$store.getters.showFullScreenDialog;
    },
    getEmail() {
      return this.$store.getters.getUserEmail;
    }
  },
  methods: {
    getCharacter(index) {
      switch ( index ) {
        case 0:
          return "A) ";
        case 1:
          return "B) ";
        case 2:
          return "C) ";
        case 3:
          return "D) ";
        case 4:
          return "E) ";
        case 5:
          return "F) ";
        case 6:
          return "G) ";
        case 7:
          return "H) ";
        case 8:
          return "I) ";
      }
    },
    hideFullScreenDialog() {
      this.$store.commit("setshowSolutionFullScreenDialog", false);
    }
  }
}
</script>
<style scoped>
/*#watermark {*/
/*    position: relative;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
#watermark {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  word-break: break-all;

}

.content {

  position: relative;
  z-index: 999;
}

p {
  word-break: break-word;
}

/*.background {*/
/*    color: #b4b4b4;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    z-index: 203;*/
/*    line-height: 300%;*/
/*    overflow: hidden;*/
/*    -webkit-transform: rotate(-10deg);*/
/*    -moz-transform: rotate(-10deg);*/

/*}*/
</style>
