<template>
  <v-layout>
    <v-card fill-height height="100%" width="100%">
      <v-tabs color="indigo" fixed-tabs>
        <v-tab>Summary</v-tab>
        <v-tab>Solutions</v-tab>
        <v-tab-item>
          <v-container fluid grid-list-md text-xs-center>
            <v-layout v-if="!showProgress" row wrap>
              <v-flex xs12>
                <div class="headline">Summary</div>
              </v-flex>

              <v-flex md6 sm6 xs12>
                <v-card>
                  <!-- <div style="width:100%"> -->
                  <!-- <div class="headline indigo--text">MCQs Attempted in Last 7 Days</div> -->
                  <!-- <hr class="red darken-1" /> -->
                  <GChart :data="chartData" :options="chartOptions" type="PieChart"/>
                  <!-- <div class="subheading ma-2" v-html="oneliner.text"></div> -->
                  <!-- </div> -->
                </v-card>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-card width="100%">

                  <!-- <v-card-title primary-title width="100%"> -->
                  <!-- <div style="width:100%"> -->
                  <!-- <div class="headline indigo--text">MCQs Attempted in Last 7 Days</div> -->
                  <!-- <hr class="red darken-1" /> -->
                  <GChart :data="totalData" :options="chartOptions" type="PieChart"/>
                  <!-- <div class="subheading ma-2" v-html="oneliner.text"></div> -->
                  <!-- </div> -->
                  <!-- </v-card-title> -->
                </v-card>
              </v-flex>
              <!-- <GChart type="PieChart" :data="chartData" :options="chartOptions" /> -->

              <v-flex v-if="correct" md4 xs12>
                <v-card class="text-center">
                  <div class="headline">Correct</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="display-3 green--text">{{ correct }}</div>
                    </template>
                    <span>The Number of answers which were correct</span>
                  </v-tooltip>
                  <v-btn class="ma-2" color="green" dark outlined @click="showFullScreenDialog(1)">
                    Click Here
                  </v-btn>

                </v-card>
              </v-flex>
              <v-flex v-if="wrong" md4 xs12>
                <v-card class="text-center">
                  <div class="headline">Wrong</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="display-3 red--text">{{ wrong }}</div>
                    </template>
                    <span>The Number of answers which were marked wrong</span>
                  </v-tooltip>
                  <v-btn class="ma-2" color="green" outlined @click="showFullScreenDialog(2)">Click
                    Here
                  </v-btn>
                </v-card>
              </v-flex>

              <v-flex v-if="unanswered" md4 xs12>
                <v-card class="text-center">
                  <div class="headline">Unanswered</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="display-3 grey--text">{{ unanswered }}</div>
                    </template>
                    <span>The Number of answers which were not attempted</span>
                  </v-tooltip>
                  <v-btn class="ma-2" color="green" outlined @click="showFullScreenDialog(3)">Click
                    Here
                  </v-btn>

                </v-card>
              </v-flex>
              <v-flex xs12/>

              <v-flex xs12>
                <v-card class="text-center">
                  <div class="headline">Score</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="display-3 primary--text">{{ (correct * 4) - wrong }}
                      </div>
                    </template>
                    <span>(Correct X 4) - Wrong i.e. ({{ correct }} X 4) - {{ wrong }}</span>
                  </v-tooltip>
                </v-card>
              </v-flex>
              <v-flex xs12>
                <v-card class="text-center">
                  <div class="headline">Rank</div>
                  <div class="display-3 indigo--text"># {{ rank }}
                  </div>
                  <v-btn class="ma-2" color="green" outlined @click="showMeritList">Click
                    Here For Merit List
                  </v-btn>
                </v-card>
              </v-flex>
              <v-flex v-if="subjectDetails.length > 0" class="ma-2" xs12>
                <div class="headline">Subject Wise Detailed Analysis</div>
                <p></p>
                <div
                    class="subtitle-2"
                >Score more than 80% in each subject and improve your chances to clear exam with flying
                  colors.
                </div>
                <v-flex xs12>
                  <v-avatar size="36px">
                    <v-icon class="redText" large>mdi-alert-circle</v-icon>
                  </v-avatar>
                  You need to study and practise a lot.
                </v-flex>
                <v-flex xs12>
                  <v-avatar size="36px">
                    <v-icon class="yellowText" large>mdi-shield-alert</v-icon>
                  </v-avatar>
                  Some more practise is required. You are almost there.
                </v-flex>
                <v-flex xs12>
                  <v-avatar size="36px">
                    <v-icon class="greenText" large>mdi-check-box-outline</v-icon>
                  </v-avatar>
                  You are almost ready for the exam.
                </v-flex>
                <v-list two-line>
                  <template v-for="(item, index) in subjectDetails">
                    <v-list-item
                        :key="index"
                        @click="showDialog('Correct:'+item.c + '\n Wrong:'+ item.w +'\n Unanswered:'+ item.u+' \nTotal:'+item.total, item.name, item.image, item.class)"

                    >
                      <v-list-item-avatar>
                        <v-icon :class="item.class" large>{{ item.image }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="item.name"/>
                        <!-- <p>{{item[index].key.c}}</p> -->
                        <v-list-item-subtitle
                            :class="item.class"
                        >{{ item.c }}/{{ item.c + item.u + item.w }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            :title="'Correct:'+item.c + '\n Wrong:'+ item.w +'\n Unanswered:'+ item.u+' \nTotal:'+item.total"
                        >Correct: {{ item.c }} Wrong:{{ item.w }} Unanswered:{{ item.u }} Total:
                          {{ item.total }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
              <!--                            <v-flex xs12>-->
              <!--                                <v-card class="text-center">-->
              <!--                                    <p class="caption">Footnote:<br>-->
              <!--                                        Ranks are currently not provided as the number of users who attempted the test-->
              <!--                                        is low.</p>-->
              <!--                                </v-card>-->
              <!--                            </v-flex>-->
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid grid-list-xs style="min-height: 100vh; height:100%;">
            <v-layout row wrap>
              <v-flex md9 xs12>
                <v-card class="mb-2" height="100%" min-height="100vh" width="100%">
                  <v-card-title class="ma-2 pa-0" primary-title>
                    <div style="width:100%">
                      <v-layout class="hidden-sm-and-up" row wrap>
                        <v-flex xs6>
                          <v-btn
                              v-if="currentQuestionNumber > 1"
                              color="primary"
                              dark
                              fab
                              style="float: left"
                              @click="previous()"
                          >
                            <v-icon dark left>mdi-chevron-left</v-icon>

                          </v-btn>
                        </v-flex>
                        <v-flex xs6>
                          <v-btn
                              v-if="currentQuestionNumber <= numberofQuestions-1"
                              color="primary"
                              dark
                              fab
                              right
                              style="float: right"
                              @click="saveAndNext()"
                          >

                            <v-icon dark right>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <div id="watermark" class="ma-2 pa-2" style="background-repeat: repeat;"
                           v-bind:style="{ backgroundImage: `url('${getWatermark}')` }">
                        <div class="content">
                          <div class="headline font-weight-light">Question
                            {{ currentQuestionNumber }}
                          </div>
                          <div style="width:100% ; ">{{ currentQuestion.q }}</div>

                          <v-img v-if="currentQuestion.i" :src="currentQuestion.i" alt="loading image"
                                 aspect-ratio="1"
                                 contain height="300px"
                                 lazy-src="https://picsum.photos/id/11/100/60"
                                 width="250px">
                            <template v-slot:placeholder>
                              <v-row
                                  align="center"
                                  class="fill-height ma-0"
                                  justify="center"
                              >
                                <v-progress-circular color="indigo lighten-5"
                                                     indeterminate/>
                              </v-row>
                            </template>
                          </v-img>
                          <!--TODO: Radiobuttons are not visible in dark theme. Make sure to correct it.-->
                          <v-radio-group v-model="radios" :mandatory="false" class="disable-events">
                            <v-radio
                                v-for="(option, index) in currentQuestion.o"
                                :key="index"
                                :checked="currentQuestion.useranswered === index"

                                :class="getBorderColor(currentQuestion.useranswered, currentQuestion.c, index)"
                                :value="index"
                                outline
                                style=""
                            >
                              <template v-slot:label>
                                <div
                                    :class="[getTextColor(currentQuestion.useranswered, currentQuestion.c, index)]"

                                >{{ option }}
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                          <div v-if="currentQuestion.d.length>0" class="subtitle-2 font-weight-regular"
                               style="">Description:
                          </div>
                          <div class="subtitle-1 font-weight-light" style=""
                               v-html="currentQuestion.d"></div>
                        </div>
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-actions class="mb-4">
                    <div>
                      <v-flex xs12>
                        <v-btn
                            v-if="currentQuestionNumber > 1"
                            class="mb-3 mt-3"
                            color="primary"
                            dark
                            @click="previous()"
                        >
                          <v-icon dark left>mdi-chevron-left</v-icon>
                          Prev
                        </v-btn>
                        <v-btn
                            v-if="currentQuestionNumber <= numberofQuestions-1"
                            class="ml-3 mb-3 mt-3"
                            color="primary"
                            dark
                            @click="saveAndNext()"
                        >
                          Next
                          <v-icon dark right>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-flex>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex class="hidden-sm-and-down" md3>
                <v-layout ref="allQuestions" v-keep-scroll-position class="sibling-2 pa-2 ma-2" row
                          wrap>
                  <v-flex v-for="i in numberofQuestions" :key="`4${i}`" xs3>
                    <!--  -->
                    <v-btn
                        :color="getColor(i)"
                        class="pa-2 ma-2 black--text"

                        fab
                        small
                        v-on:click.stop="showQuestionNumber(i)"
                    >{{ i }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider class="ma-3"/>

                <v-layout row wrap>
                  <v-flex xs6>
                    <v-card class="text-center" color="red" dark>
                      <v-card-text class="mx-auto">Not Attempted</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs6>
                    <v-card class="text-center" color="cyan" dark>
                      <v-card-text class="mx-auto">Attempted</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs6>
                    <v-card class="text-center" color="orange" dark>
                      <v-card-text class="mx-auto">Marked for Review</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex xs6>
                    <v-card class="text-center" color="white" dark>
                      <v-card-text class="mx-auto black--text">Not Visited</v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <v-icon :class="dialogData.class">{{ dialogData.icon }}</v-icon>
          {{ dialogData.title }}
        </v-card-title>
        <v-card-text style="white-space: pre-line;">{{ dialogData.text }}</v-card-text>
      </v-card>
    </v-dialog>
    <FullScreenDialog :full-screen-dialog="fullScreenDialog" :sorted-result="sortedResult"
                      :title-full-screen-dialog="titleFullScreenDialog"/>


    <MeritListDialog v-if="meritListDialog" :is-it-daily-test="isItDailyTest" :testid="$route.params.id"/>

  </v-layout>
</template>


<script>
import {APIService} from "../APIService";
import {GChart} from "vue-google-charts";
import FullScreenDialog from "./FullScreenDialog";
import MeritListDialog from "../components/MeritListDialog";

const apiService = new APIService();

export default {
  metaInfo: {
    title: 'Solution',
    titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {
        name: 'description',
        content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
      }
    ]
  },
  components: {MeritListDialog, FullScreenDialog, GChart},
  data: () => ({
    isItDailyTest: false,


    snackbar: true,
    radios: null,
    drawers: true,
    numberofQuestions: 0,
    testQuestions: [],
    subjectDetails: [],
    showProgress: true,
    rank: 0,
    correct: "",
    dialog: false,
    fullScreenDialog: false,

    titleFullScreenDialog: "",
    sortedResult: [],
    correctAnswered: [],
    wrongAnswered: [],
    unAnswered: [],
    dialogData: {},
    wrong: "",
    unanswered: "",
    chartData: [],
    chartOptions: {
      chart: {
        title: "Your Performance"
      }
    },
    totalData: []
  }),
  props: {
    source: String
  },
  mounted: function () {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      // console.log("Route ID: " + this.$route.params.id);
      this.$store.commit("setShowProgress", true);
      this.showProgress = true;


      let path = this.$router.currentRoute.name;
      // eslint-disable-next-line no-console
      // console.log(path);
      let isDailyPresent = path.includes("solutionDaily");
      if (isDailyPresent) {
        // eslint-disable-next-line no-console
        // console.log(this.$route.params.id);
        this.isItDailyTest = true;
        this.getDailyTestSolutionFromServer(this.$route.params.id);

      } else {
        this.getWeeklyTestSolutionFromServer(this.$route.params.id);

        // this.getThisSolutionFromServer(this.$route.params.id);

      }
    });
  },
  computed: {
    meritListDialog: {
      get() {
        return this.$store.getters.dshowMeritListDialog;

      },
      set(value) {
        this.$store.commit("setshowMeritListDialog", value);
      }
    },
    getWatermark() {
      let waterMark = "https://duparedentalclinic.com/be-webapp/watermark/" + this.getEmail;
      return this.$vuetify.theme.dark ? waterMark + '/dark' : waterMark + '/light';
    },
    getEmail() {
      return this.$store.getters.getUserEmail;
    },
    realQuestionNumber() {
      if (this.$store.getters.getCurrentQuestionNumber > 0) {
        return this.$store.getters.getCurrentQuestionNumber - 1;
      }
      return 0;
    },
    currentQuestionNumber() {
      return this.$store.getters.getCurrentQuestionNumber;
    },
    currentQuestion() {
      const app = this;
      app.showProgress = false;

      if (this.testQuestions.length === 0) {
        return 0;
      }
      let cq = this.testQuestions[this.realQuestionNumber];
      if (
          cq["useranswered"] === undefined ||
          (cq["useranswered"] === "" && cq["useranswered"] !== 0)
      ) {
        // console.log("this is value of undefined");
        app.radios = null;
      } else {
        app.radios = cq["useranswered"];
      }
      return cq;
    }
  },
  watch: {
    // currentQuestionNumber(newCount, oldCount) {
    //   // Our fancy notification (2).
    //   // console.log(`We have ${newCount} fruits now, yaay!`);
    //   // this.showThisQuestion();
    // }
  },
  beforeDestroy() {
    this.$store.commit("setQuestionNumber", 1);
    this.testQuestions = [];
  },
  methods: {
    showMeritList() {
      this.$store.commit("setshowMeritListDialog", true);
    },
    next() {
    },
    showQuestionNumber(i) {
      var messageDisplay = this.$refs.allQuestions;
      this.scrollPosition = messageDisplay.scrollTop;
      // console.log("SP: "+);
      // messageDisplay.scrollTop = messageDisplay.scrollHeight;
      // console.log(i);
      this.$store.commit("setQuestionNumber", i);
    },
    getBorderColor: function (ua, ca, index) {
      // console.log(ua + "----------" + ca);
      if (ua === ca && index === ca) {
        return "greenborder";
      } else if (index === ua) {
        return "redborder";
      } else if (index === ca) {
        return "greenborder";
      } else {
        return "";
      }
    },

    getTextColor: function (ua, ca, index) {
      // console.log(ua + "----------" + ca);
      if (ua === ca && index === ca) {
        return "green--text";
      } else if (index === ua) {
        return "red--text";
      } else if (index === ca) {
        return "green--text";
      } else {
        return "";
      }
    },
    getColor: function (d) {
      // console.log(d);
      if (this.testQuestions.length === 0) {
        return "blue-grey lighten-2";
      }
      // if (this.testQuestions.length == d) {
      // }

      let s = d - 1;
      // console.log(
      //   "Status of " + s + " is " + this.testQuestions[s][["status"]]
      // );

      // var realQuestionNumber = app.currentQuestionNumber - 1;
      // console.log(d + "  -  " + s);
      if (this.testQuestions[s]["status"] === 999) {
        // 0 unseen, 1 not attempted, 2 attempted, 3 mark for review

        return "white";
      } else if (this.testQuestions[s]["status"] === 0) {
        return "red";
      } else if (this.testQuestions[s]["status"] === 2) {
        return "cyan";
      } else if (this.testQuestions[s]["status"] === 3) {
        return "orange";
      } else if (this.testQuestions[s]["status"] === 1) {
        // console.log("returning red accent-4");
        return "red accent-4";
      }
    },


    showThisQuestion() {
      if (this.testQuestions[this.realQuestionNumber] !== undefined) {
        // console.log("NOT UNDEFINED");
        // var s = this.currentQuestion;
        // this.testQuestions[this.realQuestionNumber]["status"] = 1;
        // console.log(
        // JSON.stringify(this.testQuestions[this.realQuestionNumber])
        // );
        // var co = this.getColor(this.realQuestionNumber);
        // console.log(co);
      } else {
        // console.log("UNDEFINED");
      }

      //  var messageDisplay = this.$refs.allQuestions;
      //   messageDisplay.scrollTop = this.scrollPosition;
      //   console.log("Scroll Position SEt at: " + this.scrollPosition);
    },
    previous() {
      window.scrollTo(0, 0);
      let maxLimit = this.currentQuestionNumber - 1;
      // console.log(maxLimit);
      if (maxLimit > 0) {
        // console.log("Showing Next Question");
        this.showQuestionNumber(maxLimit);
      } else {
        this.showQuestionNumber(1);
      }
    },
    saveAndNext() {
      window.scrollTo(0, 0);
      let maxLimit = this.currentQuestionNumber + 1;
      // console.log(maxLimit);
      if (this.numberofQuestions >= maxLimit) {
        // console.log("Showing Next Question");
        this.showQuestionNumber(maxLimit);
      }
    },

    clearResponse() {
      this.radios = null;
      this.testQuestions[this.realQuestionNumber]["status"] = 1;
      this.testQuestions[this.realQuestionNumber]["useranswered"] = "";
    },
    showDialog(text, title, icon, classe) {
      this.dialogData = {text: text, title: title, icon: icon, class: classe};

      this.dialog = true;

    },
    processSubjectCount(array) {
      let fruits = [];

      Object.keys(array).forEach(function (key) {
        let element = array[key];
        let total = element.c + element.w + element.u;
        let percentage = (element.c / total) * 100;
        if (percentage > 80) {
          element.class = "greenText";
          element.image = "check_box";
        } else if (percentage < 80 && percentage > 50) {
          element.class = "yellowText";
          element.image = "warning";
        } else {
          element.class = "redText";
          element.image = "error";
        }
        element.cent = percentage;
        element.total = total;
        element.name = key + " - " + parseFloat(percentage).toFixed(2) + "%";
        fruits.push(element);
      });

      return fruits;
    },
    showFullScreenDialog(value) {
      const app = this;
      if (value === 1) {
        app.sortedResult = app.correctAnswered;
        app.titleFullScreenDialog = "Correct"
      } else if (value === 2) {
        app.sortedResult = app.wrongAnswered;
        app.titleFullScreenDialog = "Wrong"

      } else if (value === 3) {
        app.sortedResult = app.unAnswered;
        app.titleFullScreenDialog = "Unanswered"

      }
      this.$store.commit("setshowSolutionFullScreenDialog", true);

      app.fullScreenDialog = true;
    },
    getWeeklyTestSolutionFromServer(testID) {
      const app = this;

      apiService.getWeeklyTestSolution(testID).then(data => {
        // eslint-disable-next-line no-console
        // console.log(data);
        app.testQuestions = data.data;

        let datasss = data.data;

        app.correctAnswered = [];
        app.wrongAnswered = [];
        app.unAnswered = [];

        datasss.forEach(function (item) {

          if (item.useranswered === item.c) {
            app.correctAnswered.push(item);
          } else if (item.useranswered === 999) {
            app.unAnswered.push(item)
          } else {
            app.wrongAnswered.push(item)
          }
        });

        // console.log(correctAnswered);
        // console.log(wrongAnswered);

        app.rank = data.rank;
        app.correct = data.correct;
        app.wrong = data.wrong;
        app.unanswered = data.unanswered;

        app.subjectDetails = app.processSubjectCount(data.subCt);

        app.chartData = [
          ["Item", "Value"],
          ["Correct (" + app.correct + ")", parseInt(app.correct)],
          ["Wrong (" + app.wrong + ")", parseInt(app.wrong)],
          ["Unanswered (" + app.unanswered + ")", parseInt(app.unanswered)]
        ];

        let score = parseInt((app.correct * 4 - app.wrong).toString());
        // console.log("Score: "+score)

        if (score < 0) {
          score = 0;
        }

        let total =
            (parseInt(app.correct) +
                parseInt(app.wrong) +
                parseInt(app.unanswered)) *
            4;
        // console.log("Total: "+total)

        app.totalData = [
          ["Item", "Value"],
          ["Your Score", score],
          ["Total", total]
        ];
        // console.log(app.testQuestions);
        app.$store.commit("setShowProgress", false);

        app.numberofQuestions = data.data.length;
        if (app.testQuestions[app.realQuestionNumber] !== undefined) {
          // console.log("NOT UNDEFINED");
          // app.showThisQuestion();
        } else {
          // console.log("UNDEFINED");

          app.currentQuestion = {
            q: "Question in not available",
            o1: "option 1 is Not Found",
            o2: "option 2 is this",
            o3: "option 3 is this",
            o4: "option 4 is this",
            id: "14456"
          };
          // return
          // console.log("-------" + JSON.stringify(app.currentQuestion));
        }
      });
    },
    getDailyTestSolutionFromServer: function (testID) {
      const app = this;

      apiService.getDailyTestSolution(testID).then(data => {
        // eslint-disable-next-line no-console
        // console.log(data);
        app.testQuestions = data.data;

        let datasss = data.data;

        app.correctAnswered = [];
        app.wrongAnswered = [];
        app.unAnswered = [];


        datasss.forEach(function (item) {

          if (item.useranswered === item.c) {
            app.correctAnswered.push(item);
          } else if (item.useranswered === 999) {
            app.unAnswered.push(item)
          } else {
            app.wrongAnswered.push(item)
          }
        });

        // console.log(correctAnswered);
        // console.log(wrongAnswered);
        // console.log(unAnswered);

        app.rank = data.rank;
        app.correct = data.correct;
        app.wrong = data.wrong;
        app.unanswered = data.unanswered;

        app.subjectDetails = app.processSubjectCount(data.subCt);

        app.chartData = [
          ["Item", "Value"],
          ["Correct (" + app.correct + ")", parseInt(app.correct)],
          ["Wrong (" + app.wrong + ")", parseInt(app.wrong)],
          ["Unanswered (" + app.unanswered + ")", parseInt(app.unanswered)]
        ];

        let score = parseInt((app.correct * 4 - app.wrong).toString());
        // console.log("Score: "+score)

        if (score < 0) {
          score = 0;
        }

        let total =
            (parseInt(app.correct) +
                parseInt(app.wrong) +
                parseInt(app.unanswered)) *
            4;
        // console.log("Total: "+total)

        app.totalData = [
          ["Item", "Value"],
          ["Your Score", score],
          ["Total", total]
        ];
        // console.log(app.testQuestions);
        app.$store.commit("setShowProgress", false);

        app.numberofQuestions = data.data.length;
        if (app.testQuestions[app.realQuestionNumber] !== undefined) {
          // console.log("NOT UNDEFINED");
          // app.showThisQuestion();
        } else {
          // console.log("UNDEFINED");

          app.currentQuestion = {
            q: "Question in not available",
            o1: "option 1 is Not Found",
            o2: "option 2 is this",
            o3: "option 3 is this",
            o4: "option 4 is this",
            id: "14456"
          };
          // return
          // console.log("-------" + JSON.stringify(app.currentQuestion));
        }
      });
    },


    // eslint-disable-next-line no-unused-vars
    getThisSolutionFromServer(testID) {
      // eslint-disable-next-line no-unused-vars
      const app = this;

      // APIService.getTestSolutionFromServer(testID).then(data => {
      //     // console.log(data);
      //     app.testQuestions = data.data;
      //
      //     let datasss = data.data;
      //
      //     app.correctAnswered = [];
      //     app.wrongAnswered = [];
      //     app.unAnswered = [];
      //
      //     datasss.forEach(function (item) {
      //
      //         if (item.useranswered === item.c) {
      //             app.correctAnswered.push(item);
      //         } else if (item.useranswered === 999) {
      //             app.unAnswered.push(item)
      //         } else {
      //             app.wrongAnswered.push(item)
      //         }
      //     });
      //
      //     // console.log(correctAnswered);
      //     // console.log(wrongAnswered);
      //     // console.log(unAnswered);
      //
      //     app.correct = data.correct;
      //     app.wrong = data.wrong;
      //     app.unanswered = data.unanswered;
      //
      //     app.subjectDetails = app.processSubjectCount(data.subCt);
      //
      //     app.chartData = [
      //         ["Item", "Value"],
      //         ["Correct (" + app.correct + ")", parseInt(app.correct)],
      //         ["Wrong (" + app.wrong + ")", parseInt(app.wrong)],
      //         ["Unanswered (" + app.unanswered + ")", parseInt(app.unanswered)]
      //     ];
      //
      //     let score = parseInt(app.correct * 4 - app.wrong);
      //     // console.log("Score: "+score)
      //
      //     if (score < 0) {
      //         score = 0;
      //     }
      //
      //     let total =
      //         (parseInt(app.correct) +
      //             parseInt(app.wrong) +
      //             parseInt(app.unanswered)) *
      //         4;
      //     // console.log("Total: "+total)
      //
      //     app.totalData = [
      //         ["Item", "Value"],
      //         ["Your Score", score],
      //         ["Total", total]
      //     ];
      //     // console.log(app.testQuestions);
      //     app.$store.commit("setShowProgress", false);
      //
      //     app.numberofQuestions = data.data.length;
      //     if (app.testQuestions[app.realQuestionNumber] !== undefined) {
      //         // console.log("NOT UNDEFINED");
      //         // app.showThisQuestion();
      //     } else {
      //         // console.log("UNDEFINED");
      //
      //         app.currentQuestion = {
      //             q: "Question in not available",
      //             o1: "option 1 is Not Found",
      //             o2: "option 2 is this",
      //             o3: "option 3 is this",
      //             o4: "option 4 is this",
      //             id: "14456"
      //         };
      //         // return
      //         // console.log("-------" + JSON.stringify(app.currentQuestion));
      //     }
      // });
    }
  }
};
</script>

<style scoped>
.disable-events {
  pointer-events: none;

}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}

>>> .sibling-2 {
  /* overflow: auto; */

  max-height: 48vh;
  overflow-y: auto;
}

>>> .flip-card {
  font-size: 2rem !important;
}

.fade-enter-active {
  transition: opacity 1.5s;
}

.fade-leave-active {
  opacity: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

>>> .v-input__control {
  width: 100%;
}

>>> .v-radio {
  margin: 3px;
}

.greenText {
  color: green !important;
}

.yellowText {
  color: #fb8c00 !important;
}

.redText {
  color: red !important;
}

.greenborder {
  border: 1px solid green;
  /* padding: 2px; */
  margin: 2px;
}

.redborder {
  border: 1px solid red;
  /* padding: 2px; */
  margin: 2px;
}


#watermark {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  word-break: break-word;

}

.content {

  position: relative;
  z-index: 3;
  overflow: hidden;

}

.background {
  color: #d5d5d5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  line-height: 4vw;
  overflow: hidden;
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);

}

>>> .v-label {
  width: 100% !important;
  height: auto !important;
}
</style>
